import { Serie } from "@nivo/line";

export const makeBidOfferPairsRelativeToFPN = (chartData: Serie[]): Serie[] => {
  if (chartData.length === 0) {
    return [];
  }
  const fpnId = "finalPhysicalNotification";

  const fpn = chartData.find((x) => x.id === fpnId)!;

  const nonFPNList = chartData.filter((x) => x.id !== fpnId);

  const nonFPNDataRelativeToFPN = nonFPNList.map((item) => {
    const updatedData = item.data.map((dataEntry) => {
      const matchingFPNDataEntry = fpn.data.find(
        (fpnDataEntry) =>
          (fpnDataEntry.x as Date)?.getTime() ===
            (dataEntry.x as Date)?.getTime() &&
          fpnDataEntry.settlementPeriod === dataEntry.settlementPeriod
      );

      if (matchingFPNDataEntry) {
        return {
          ...dataEntry,
          y: <number>dataEntry.y + <number>matchingFPNDataEntry.y,
        };
      }

      return dataEntry;
    });
    return { ...item, data: updatedData };
  });

  return [...nonFPNDataRelativeToFPN, fpn];
};
