import TabContentWithErrorHandling from "components/components/Tabs/TabContentWithErrorHandling/TabContentWithErrorHandling";
import { DescriptionText } from "components/components/Tabs/TabDescription/style";
import { useBmuViewContext } from "contexts/BmuViewContext";
import React, { useEffect, useState } from "react";

import BidOfferChartContainer from "./BidOfferChartContainer/BidOfferChartContainer";
import BidOfferTables from "./BidOfferTables/BidOfferTables";

const BmuViewBidOffer: React.FC = () => {
  const {
    selectedBmu,
    bidOfferDetails: { status, request },
  } = useBmuViewContext();
  const [selectedSettlementPeriod, setSelectedSettlementPeriod] =
    useState<Date | null>(null);

  useEffect(() => {
    setSelectedSettlementPeriod(null);
  }, [selectedBmu]);

  useEffect(() => {
    if (selectedSettlementPeriod) {
      document.getElementById("bid-offer-tables")?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [selectedSettlementPeriod]);

  const description = `
          Bid-Offer Data (BOD) for a BM Unit and Settlement Period is made up of
          a series of Bid-Offer pairs. The Levels in MW of up to ten pairs of
          BODs may be displayed on the graph along with the Final Physical
          Notification (FPN) for each Settlement Period. The BODs are displayed
          on the graph as relative to the FPN. The Bid and Offer Prices in £/MWh
          are shown when hovering over the graph.`;

  return (
    <>
      <DescriptionText>{description}</DescriptionText>
      <TabContentWithErrorHandling status={status} fetchData={request}>
        <>
          <BidOfferChartContainer onSliceClick={setSelectedSettlementPeriod} />
          <br />
          <BidOfferTables settlementPeriodDatetime={selectedSettlementPeriod} />
        </>
      </TabContentWithErrorHandling>
    </>
  );
};

export default BmuViewBidOffer;
