import PageMeta from "components/components/Meta/PageMeta";
import React from "react";

const Summary: React.FC = () => (
  <>
    <PageMeta
      title="Summary"
      description="Summary page, only available on dev and test environments"
    />
    <div>
      <h1 data-test-id="summary-heading">Summary</h1>
    </div>
  </>
);

export default Summary;
