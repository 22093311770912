import BidOfferChart from "components/dataVisualization/balancingMechanismTabs/BidOffer/bmuView/BidOfferChart/BidOfferChart";
import { BidOfferChartHeader } from "components/dataVisualization/balancingMechanismTabs/BidOffer/bmuView/BidOfferChartContainer/style";
import { NoDataAvailable } from "components/dataVisualization/balancingMechanismTabs/style";
import ChartFilterButton from "components/dataVisualization/chartComponents/ChartFilterButton/ChartFilterButton";
import { ChartBody } from "components/dataVisualization/chartComponents/style";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import { useBmuViewContext } from "contexts/BmuViewContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import {
  bidOfferDatasetDisplayOrder,
  pointShapeForBidOfferDataset,
  bidOfferDatasetDisplayName,
} from "models/bidOffer/bidOfferChartData";
import React, { useMemo } from "react";
import { bidOfferDatasetColours } from "styles/colours";
import {
  getSingleCategoryChartDatasetModelFromSeries,
  transformRangedChartData,
} from "utils/ChartDataUtils/rangedChartDataTransformer/rangedChartDataTransformer";
import { chartWouldBeEmpty } from "utils/chartHelpers";

import { makeBidOfferPairsRelativeToFPN } from "./BidOfferChartContainerHelper";

type Props = {
  onSliceClick: (settlementPeriodStartTime: Date) => void;
};

const BidOfferChartContainer: React.FC<Props> = ({ onSliceClick }) => {
  const {
    selectedBmu,
    dateFilter,
    bidOfferDetails: { data, status },
  } = useBmuViewContext();
  const rawChartData = data?.chartData ?? null;

  const chartData = useMemo(
    () =>
      rawChartData && dateFilter
        ? transformRangedChartData(
            rawChartData,
            dateFilter,
            pointShapeForBidOfferDataset,
            bidOfferDatasetColours
          )
        : null,
    [dateFilter, rawChartData]
  );

  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);

  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setMatchingDatasetsVisibility,
  } = useDatasetCategoriesWithVisibilities(
    getSingleCategoryChartDatasetModelFromSeries({
      datasetDisplayOrder: bidOfferDatasetDisplayOrder,
      data: chartData,
      title: "Bid offer data",
      pointShapeDict: pointShapeForBidOfferDataset,
      displayNameDict: bidOfferDatasetDisplayName,
    })
  );

  if (dateFilter === null || selectedBmu === null || chartData === null) {
    return <></>;
  }

  const filteredChartData = chartData.filter((serie) =>
    datasetCategoriesToDisplay.some((datasetCategory) =>
      datasetCategory.datasets.some((dataset) => dataset.dataKey === serie.id)
    )
  );

  const chartDataRelativeToFPN =
    makeBidOfferPairsRelativeToFPN(filteredChartData);

  if (chartWouldBeEmpty(status, chartData)) {
    return (
      <NoDataAvailable>
        No data available for this BMU in this timeframe
      </NoDataAvailable>
    );
  }

  return (
    <FullWidthChartSection>
      <>
        <BidOfferChartHeader>
          <ChartFilterButton
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            showChartFilterDrawer={showChartFilterDrawer}
          />
        </BidOfferChartHeader>
        <ChartBody>
          {showChartFilterDrawer && (
            <ChartFilterDrawer
              setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
              toggleChartFilterDrawer={toggleChartFilterDrawer}
              datasetCategories={allDatasetCategories}
            />
          )}
          <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
            <BidOfferChart
              datasetCategories={datasetCategoriesToDisplay}
              series={chartDataRelativeToFPN}
              dataFetchStatus={status}
              dateFilter={dateFilter}
              onSliceClick={onSliceClick}
            />
          </div>
        </ChartBody>
      </>
    </FullWidthChartSection>
  );
};

export default React.memo(BidOfferChartContainer);
